import { Children, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  children: ReactNode;
  className?: string;
};

export default ({ className = "", children }: Props) => {
  const amountOfChildren = Children.toArray(children).length;
  return (
    <div
      className={twMerge(
        "grid w-full !flex-nowrap !items-stretch gap-4",
        amountOfChildren === 1 &&
          "grid-cols-1 [--aspect:2.248/1] [--headline-font-size:5.125rem]",
        amountOfChildren === 2 &&
          "grid-cols-2 [--aspect:1.479/1] [--headline-font-size:3.5rem]",
        amountOfChildren === 3 &&
          "grid-cols-3 [--aspect:1.354/1] [--headline-font-size:3rem]",
        className,
      )}
    >
      {children}
    </div>
  );
};
