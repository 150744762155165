import PageHeader from "@components/PageHeader";
import Head from "next/head";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import PageContent from "@components/PageContent";
import GraphRow from "@components/Nivo/GraphRow";
import GraphLoader from "@components/Nivo/GraphLoader";
import { useVendorContext } from "@contexts/vendor-context";
import MultiVendorSelector from "@components/MultiVendorSelector";
import Router from "next/router";
import Container from "@components/Ui/Container";

const GraphsHolder = ({
  selectedVendors,
  vendorsParam,
  currency,
}: {
  selectedVendors: string[];
  vendorsParam: string;
  currency: string;
}) => {
  if (selectedVendors.length === 0) {
    return (
      <Container className="space-y-2 p-8 text-center">
        <div className="!text-xl font-medium">No vendor selected</div>
        <div className="!text-base text-gray-300">
          Please select at least one of your vendors to see the multi vendor
          analytics
        </div>
      </Container>
    );
  } else {
    const currencyString = currency ? `&currency=${currency}` : "";
    return (
      <>
        <GraphRow className="[--aspect:5/1]">
          <GraphLoader
            endPoint={
              "graphs/multiVendors/totalSales" + vendorsParam + currencyString
            }
          />
          <GraphLoader endPoint={"graphs/multiVendors/users" + vendorsParam} />
        </GraphRow>
        <GraphRow className="[--aspect:5/1]">
          <GraphLoader
            endPoint={"graphs/multiVendors/subscriptions" + vendorsParam}
          />
        </GraphRow>
        <GraphRow className="[--aspect:5/1]">
          <GraphLoader
            endPoint={"graphs/multiVendors/singlePurchases" + vendorsParam}
          />
          <GraphLoader
            endPoint={"graphs/multiVendors/nNewSubscriptions" + vendorsParam}
          />
          <GraphLoader
            endPoint={"graphs/multiVendors/nEndedSubscriptions" + vendorsParam}
          />
          {/* <GraphLoader
            endPoint={"graphs/multiVendors/subscribers" + vendorsParam}
          /> */}
        </GraphRow>
        <GraphRow className="[--aspect:5/1]">
          <GraphLoader
            endPoint={"graphs/multiVendors/mrr" + vendorsParam + currencyString}
          />
        </GraphRow>
      </>
    );
  }
};

const processVendors = (
  selectedVendors: string[],
  availableVendors: string[],
) => {
  if (selectedVendors.length === 0) {
    return "?vendors=_"; // return a string that will not match any vendor
  }
  if (selectedVendors.length === availableVendors.length) {
    return "";
  }
  if (selectedVendors.length <= availableVendors.length / 2) {
    return `?vendors=${selectedVendors.join(",")}`;
  }
  if (selectedVendors.length > availableVendors.length / 2) {
    return `?excludedVendors=${availableVendors.filter((v) => !selectedVendors.includes(v)).join(",")}`;
  }
  return "";
};

const Index = () => {
  const { selectedVendor, vendors, multiVendorKey, getVendorSettings } =
    useVendorContext();
  const [isLoadingVendors, setIsLoadingVendors] = useState(true);
  const [selectedVendors, setVendors] = useState<string[]>([]);
  const [currencyString, setCurrencyString] = useState<string>("");
  const multiVendorSettings = getVendorSettings(multiVendorKey);
  const { t } = useTranslation();

  useEffect(() => {
    if (multiVendorKey !== selectedVendor) {
      Router.push(`/${selectedVendor}/analytics`);
    }
    if (selectedVendor && vendors && vendors.length > 0) {
      setVendors(vendors.filter((v) => v !== multiVendorKey));
      setIsLoadingVendors(false);
    }
  }, [multiVendorKey, selectedVendor, vendors]);

  if (isLoadingVendors) {
    return null;
  }

  const vendorsParam = processVendors(selectedVendors, vendors || []);

  return (
    <>
      <Head>
        <title>Sesamy Portal - Analytics</title>
      </Head>
      <div className="pb-3 pl-4">
        <PageHeader
          headline={t(multiVendorSettings?.name || "")}
          showDatePicker
          hideGlobalSearch={true}
        />
        <PageContent>
          <MultiVendorSelector
            selectedVendors={selectedVendors}
            setSelectedVendors={setVendors}
            selectedCurrency={currencyString}
            setSelectedCurrency={setCurrencyString}
          />
          <GraphsHolder
            selectedVendors={selectedVendors}
            vendorsParam={vendorsParam}
            currency={currencyString}
          />
        </PageContent>
      </div>
    </>
  );
};

export default Index;
