import Icon from "@components/Ui/Icon";
import { Vendor } from "@contexts/vendor-context";
import { getImgProxyUrl } from "@utils/image-proxy";
import { useTranslation } from "react-i18next";

type Props = {
  vendor: Vendor | undefined;
  selectedVendors: string[];
  setSelectedVendors: (vendors: string[]) => void;
};

export default ({ vendor, selectedVendors, setSelectedVendors }: Props) => {
  const { t } = useTranslation();
  const { name, vendorId, logo } = vendor || {};
  const isSelected = selectedVendors.includes(vendorId || "");
  if (!vendor) return null;
  const setUnsetSelected = () => {
    if (isSelected) {
      setSelectedVendors(selectedVendors.filter((v) => v !== vendorId));
    }
    if (!isSelected) {
      setSelectedVendors([vendorId || "", ...selectedVendors]);
    }
  };
  return (
    <button
      className="w-full space-x-3 rounded border-b border-gray-600 p-2 transition-colors row hover:bg-gray-600 active:translate-y-px "
      onClick={() => setUnsetSelected()}
    >
      <span className="relative aspect-square w-[28px] overflow-hidden rounded-sm bg-white row">
        {logo && (
          <img
            className="w-full p-0.5"
            src={getImgProxyUrl(logo, ["rs:fill:64:0"])}
            alt={t("vendor_logo", { vendor: name }) || ""}
          />
        )}
        {!logo && <Icon name="sesamy-simple" className="text-xl text-black" />}
      </span>
      <span className="flex flex-1 items-start space-y-1 text-left column-left">
        <span className="!text-sm leading-none tracking-wide first-letter:uppercase">
          {name || vendorId}
        </span>
      </span>
      <span className="aspect-square w-[28px] rounded bg-gray-700 row">
        {isSelected && <Icon className="text-[14px]" name="checkmark" />}
      </span>
    </button>
  );
};
