import { ReactNode, useRef } from "react";
import { twMerge } from "tailwind-merge";
import Icon from "@components/Ui/Icon";
import Accordion from "@components/Ui/Accordion";
import useOnClickOutside from "@hooks/use-click-outside";
import Button from "@components/Ui/Button";

export default ({
  label,
  children,
  visibilityState,
  className = "",
  size = "xs",
  disabled = false,
}: {
  label: string;
  children: ReactNode;
  visibilityState: [boolean, Function];
  className?: string;
  size?: "xs" | "sm" | "base" | "lg";
  disabled?: boolean;
}) => {
  const [isOpen, setIsOpen] = visibilityState;
  const ref = useRef<HTMLDivElement>(null);
  const dropdownButtonRef = useRef<HTMLButtonElement>(null);

  useOnClickOutside(ref, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });
  return (
    <div className="relative z-10 inline-block" ref={ref}>
      <Button
        ref={dropdownButtonRef}
        onClick={() => setIsOpen(!isOpen)}
        aria-label="Toggle dropdown"
        size={size}
        className="gap-2 row-left"
        disabled={disabled}
      >
        <span className={twMerge("text:sm md:text-sm", className)}>
          {label}
        </span>
        <Icon name="chevron-down" className="flex text-xs md:text-xs" />
      </Button>
      <div className="absolute right-0 top-full rounded-md bg-gray-700 ">
        <Accordion isOpen={isOpen} className="auto-cols-min">
          {children}
        </Accordion>
      </div>
    </div>
  );
};
