import Container from "@components/Ui/Container";
import Spinner from "@components/Ui/Spinner";
import { useSelectedDatesContext } from "@contexts/selected-dates-context";
import { useVendorContext } from "@contexts/vendor-context";
import { useOnScreen } from "@hooks/use-on-screen";
import useSesamyAuthFetch from "@hooks/use-sesamy-auth-fetch";
import { StatsResponse } from "@utils/graphHelpers";
import { useRef, useState } from "react";
import useSWR from "swr";
import Nivo from "@components/Nivo/Nivo";
import Column from "@components/Ui/Column";
import { twMerge } from "tailwind-merge";

const API_URL = process.env.NEXT_PUBLIC_STATS_API_V2;

type Props = {
  endPoint: string;
  gradient?: boolean;
};

export default ({ endPoint, gradient = false }: Props) => {
  const elementRef = useRef(null);
  const isOnScreen = useOnScreen(elementRef);
  const renderedState = useState(false);
  const [rendered] = renderedState;

  const { selectedVendor } = useVendorContext();
  const { formattedDates } = useSelectedDatesContext();
  const { startDate, endDate } = formattedDates;
  const { fetcher: sesamyFetcher, isReady } = useSesamyAuthFetch();
  const queryParamsConnector = endPoint.includes("?") ? "&" : "?";
  const { data, isLoading } = useSWR<StatsResponse>(
    isReady &&
      `${API_URL}/${endPoint}${queryParamsConnector}vendorId=${selectedVendor}&fromDate=${startDate}&toDate=${endDate}`,
    sesamyFetcher,
  );

  return (
    <div
      className={twMerge(
        "relative w-full flex-1",
        !rendered && "aspect-[var(--aspect)]",
      )}
      ref={elementRef}
    >
      <Container className="min-h-full column-up">
        {((isOnScreen && !isLoading) || rendered) && (
          <Nivo
            className=""
            statsResponse={data}
            singleDate={startDate === endDate}
            startDate={new Date(startDate)}
            endDate={new Date(endDate)}
            isLegacyGraph={false}
            gradient={gradient}
            renderedState={renderedState}
          />
        )}

        {(isLoading || !rendered) && (
          <Column className="absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] gap-2 text-gray-300">
            {!isOnScreen
              ? "Loading..."
              : isLoading
                ? "Fetching data.."
                : !rendered
                  ? "Rendering graph..."
                  : null}

            <Spinner className="text-3xl" />
          </Column>
        )}
      </Container>
    </div>
  );
};
