import React, { ChangeEvent, useRef, useState } from "react";
import { Vendor, useVendorContext } from "@contexts/vendor-context";
import VendorCard from "./VendorCard";
import TextInput from "@components/Ui/TextInput";
import Button from "@components/Ui/Button";
import Row from "@components/Ui/Row";
import Dropdown from "@components/Ui/Dropdown";
import Icon from "@components/Ui/Icon";
import useOnClickOutside from "@hooks/use-click-outside";
import Column from "@components/Ui/Column";

const filterVendors = (vendors: Vendor[], text: string) =>
  text
    ? vendors.filter(
        ({ vendorId, name }) =>
          vendorId.includes(text.toLocaleLowerCase()) ||
          (name || "").toLocaleLowerCase().includes(text.toLocaleLowerCase()),
      )
    : vendors;

type VendorCardListProps = {
  selectedVendors: string[];
  setSelectedVendors: (vendors: string[]) => void;
  selectedCurrency: string;
  setSelectedCurrency: (currency: string) => void;
};

export default ({
  selectedVendors,
  setSelectedVendors,
  selectedCurrency,
  setSelectedCurrency,
}: VendorCardListProps) => {
  const {
    vendors = [],
    getVendorSettings,
    multiVendorKey,
  } = useVendorContext();
  const [textFilter, setTextFilter] = useState("");
  const [showVendorList, setShowVendorList] = useState(false);
  const vendorsWithSettings = vendors
    .map(getVendorSettings)
    .filter((vendor): vendor is Vendor => vendor !== undefined)
    .filter(({ vendorId }) => vendorId !== multiVendorKey);
  const [filteredVendors, setFilteredVendors] = useState(vendorsWithSettings);
  const [currencyVisivility, setCurrencyVisibility] = useState(false);
  const wrapperRef = useRef(null);

  const filterList = (e: ChangeEvent<HTMLInputElement>) => {
    setTextFilter(e.target.value);
    setFilteredVendors(filterVendors(vendorsWithSettings, e.target.value));
  };

  useOnClickOutside(wrapperRef, () => {
    setShowVendorList(false);
  });

  const getInputPlaceholder = () => {
    if (showVendorList) {
      return "Type to filter";
    }
    if (!selectedVendors.length) {
      return "Select a vendor";
    }
    if (selectedVendors.length && selectedVendors.length <= 3) {
      return selectedVendors
        .map(
          (v) =>
            vendorsWithSettings.find((vendor) => vendor.vendorId === v)?.name ||
            v,
        )
        .join(", ");
    }
    if (selectedVendors.length === vendorsWithSettings.length) {
      return "All vendors selected";
    }
    return `${selectedVendors.length} vendors selected`;
  };

  const availableCurrencies = [
    ...new Set(
      vendorsWithSettings
        .filter((vendor) => selectedVendors.includes(vendor.vendorId))
        .map((vendor) => vendor.currency),
    ),
  ].filter((currency) => currency) as string[];

  const setUnsetCurrency = (currency: string) => {
    setCurrencyVisibility(false);
    if (selectedCurrency == currency) {
      setSelectedCurrency("");
    }
    if (selectedCurrency != currency) {
      setSelectedCurrency(currency);
    }
  };

  return (
    <Row left className="!justify-between gap-4">
      <div className="relative w-[400px]" ref={wrapperRef}>
        <TextInput
          name="vendor-filter"
          tabIndex={0}
          value={textFilter}
          onChange={filterList}
          placeholder={getInputPlaceholder()}
          onFocus={() => setShowVendorList(true)}
          slim
          autoComplete="off"
        />
        {showVendorList && (
          <div className="absolute -right-[100px] left-0 top-full z-10 mt-2">
            <div className="rounded-md bg-gray-700 p-2 shadow-lg">
              <div className="custom-scrollbar scrollbar-gray-700 max-h-[calc(100vh-160px)] overflow-y-auto pb-2">
                <Row left className="gap-2 border-b border-gray-600 p-2 pb-4">
                  <Button
                    size="xs"
                    className="px-3 py-2 !text-sm"
                    onClick={() =>
                      setSelectedVendors(filteredVendors.map((v) => v.vendorId))
                    }
                  >
                    Select all
                  </Button>
                  <Button
                    size="xs"
                    className="px-3 py-2 !text-sm"
                    onClick={() => setSelectedVendors([])}
                  >
                    Clear
                  </Button>
                  <Button
                    size="xs"
                    variant="secondary"
                    className="ml-auto p-2 !text-sm"
                    onClick={() => setShowVendorList(false)}
                    title="Close"
                    aria-label="Close"
                  >
                    <Icon name="cross" />
                  </Button>
                </Row>
                <Column>
                  {filteredVendors.map((vendor) => (
                    <VendorCard
                      vendor={vendor}
                      key={vendor.vendorId}
                      selectedVendors={selectedVendors}
                      setSelectedVendors={setSelectedVendors}
                    />
                  ))}
                </Column>
              </div>
            </div>
          </div>
        )}
      </div>
      {availableCurrencies.length > 1 && (
        <div className="mx-1 ">
          <Dropdown
            label="Select currency"
            className="flex min-w-[180px]"
            visibilityState={[currencyVisivility, setCurrencyVisibility]}
          >
            {availableCurrencies.map((currency) => (
              <>
                <button
                  className="min-w-[180px] space-x-3 rounded border-b border-gray-600 p-2 transition-colors row hover:bg-gray-600 active:translate-y-px "
                  onClick={() => setUnsetCurrency(currency)}
                >
                  <span className="flex flex-1 items-start space-y-1 text-left column-left">
                    <span className="!text-sm leading-none tracking-wide first-letter:uppercase">
                      {currency}
                    </span>
                  </span>
                  <span className="aspect-square w-[28px] rounded bg-gray-700 row">
                    {selectedCurrency == currency && (
                      <Icon className="text-[14px]" name="checkmark" />
                    )}
                  </span>
                </button>
              </>
            ))}
          </Dropdown>
        </div>
      )}
    </Row>
  );
};
